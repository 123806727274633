import styled from "styled-components"
import _get from "lodash.get"
import { Link } from "gatsby"

import tags from "./tags"

const Tag = styled(Link).attrs(props => ({
  to: props.to || `/blog/tag/${props?.children?.toLowerCase()}`,
}))`
  border-radius: 12px;
  height: 24px;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #000;
  font-size: 10px;
  text-transform: uppercase;
  background: ${props =>
    _get(tags, `${props.children?.toLowerCase()}.colors.tag`)};
  position: relative;
  box-sizing: border-box;
  align-self: start;

  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
`

export default Tag
