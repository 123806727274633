import React from "react"
import styled, { css } from "styled-components"
import _get from "lodash.get"
import { Link } from "gatsby"

import tags from "./tags"

import arrowBack from "../../assets/images/arrow-back.svg"

const Tag = styled.span`
  border-radius: 12px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 0;
  border: 1px solid #000;
  font-size: 10px;
  text-transform: uppercase;
  background: ${props =>
    _get(tags, `${props?.children?.toLowerCase()}.colors.tag`)};
  position: relative;
  box-sizing: border-box;
  line-height: 10px;
  position: absolute;
  bottom: 20px;

  ${props =>
    props.selected &&
    css`
      padding-left: 26px;

      &::after {
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #000;
        position: absolute;
        align-self: flex-start;
        transform: translateX(-16px);
      }
    `}

  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
`

const Arrow = styled.div`
  width: 27px;
  height: 18px;
  background: url(${arrowBack}) no-repeat center;
  position: absolute;
  bottom: 20px;
  right: 30px;
  transform: rotate(180deg);
  opacity: 0;
  transition: opacity 0.15s ease;

  @media screen and (max-width: 640px) {
    right: 20px;
  }

  ${props =>
    props.previous &&
    css`
      right: auto;
      left: 30px;
      transform: none;

      @media screen and (max-width: 640px) {
        left: 20px;
      }
    `}
`

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  background: #fff;
  border: 1px solid #000000;
  position: relative;
  align-items: flex-start;
  margin-bottom: 42px;

  @media screen and (min-width: 981px) {
    &:hover {
      // margin-left: -6px;
      // margin-right: -6px;
      // margin-top: -6px;
      // margin-bottom: 36px;
      // padding: 26px 26px 56px;
      // transition: all 0.3s ease;

      ${Arrow} {
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: none;
    transform: skewY(-30deg);
    transform-origin: left bottom;
    height: calc(100% + 2px);
    width: 10px;
    top: -1px;
    left: calc(100% + 1px);
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: 2px solid #000;
    border-bottom: none;
    transform: skew(-60deg);
    transform-origin: right bottom;
    height: 6px;
    width: calc(100% + 2px);
    bottom: calc(100% + 1px);
    left: -1px;
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  @media screen and (max-width: 980px) {
    padding: 20px 25px;
    align-self: stretch;

    ${props =>
    props.minimized &&
    css`
        ${Arrow} {
          opacity: 1;
        }
      `}
  }

  @media screen and (max-width: 640px) {
    padding: 20px;
  }

  @media screen and (max-width: 375px) {
    padding: 10px;
  }

  ${Tag}{
    position: absolute;
    bottom: 20px;
  }
`

const Title = styled.h3`
  display: block !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin: 24px 0 12px;
  margin-top: 0px;

  ${props =>
    props.minimized &&
    css`
      margin-bottom: 0px;

      @media screen and (max-width: 980px) {
        margin-bottom: 30px;
      }
    `}
`

const Date = styled.p`
  font-size: 16px;
  color: #7d7d7d;
`

const Excerpt = styled.p`
  margin-top: 12px;
  font-size: 16px;
  line-height: 24px;
  max-height: 46px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 339px;
  margin-bottom: 20px;

  @media screen and (max-width: 980px) {
    margin-bottom: 35px;
  }

  @media screen and (max-width: 640px) {
     width: auto;
  }
`

const PostCard = ({
  post,
  minimized = false,
  previous = false,
  replace = false,
  className,
}) => {
  return (
    < Container
      className={className}
      color={_get(tags, `${post.primary_tag?.name.toLowerCase()}.colors.card`)}
      to={post.primary_tag?.name == 'case-study' ? `/case-studies/${post.slug}/` : post.fromApi ? `/blog/post?slug=${post.slug}` : `/blog/${post.slug}/`}
      state={{ post }}
      replace={replace}
      minimized={minimized}
    >
      <Title minimized={minimized}>{post.title}</Title>
      {
        !minimized && (
          <>
            <Excerpt>{post.excerpt}</Excerpt>
          </>
        )
      }
      {post.primary_tag && <Tag>{post.primary_tag?.name}</Tag>}
      <Arrow previous={previous} />
    </Container >
  )
}

export default PostCard
