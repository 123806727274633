import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Link, navigate } from "gatsby"
import Helmet from "react-helmet"
import styled, { css } from "styled-components"
import isEmail from "validator/lib/isEmail"
import serviceURL from "../../utils/service-url"
import axios from "axios"
import _get from "lodash.get"
import { useLocation } from "@reach/router";
import GhostContentAPI from '@tryghost/content-api';
import ghostConfig from '../../../.ghost.json'

import Grid from "../../components/blog/post-grid"
import PostHTML from "../../components/blog/post-html"
import ContinueButton from "../../components/continue-button"
import SEO from "../../components/seo"


import arrowBack from "../../assets/images/arrow-back.svg"
import FooterImg from "../../assets/images/footerCta.svg"
import Sub from "../../assets/images/sub.svg"
import AvatarPic from "../../assets/images/avatar.png"

const api = new GhostContentAPI({
  url: ghostConfig.production.apiUrl, // Replace with your Ghost instance URL
  key: ghostConfig.production.contentApiKey, // Replace with your Content API key
  version: 'v3', // Match your Ghost CMS version
});
const H1 = styled.h1`
  font-size: 48px;
  line-height: 50px;
  font-weight: 800;
  margin-bottom: 16px;
  max-width: 580px;
  text-align: center;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 640px) {
    width: 320px;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
  }
`
const H4 = styled.h2`
font-weight: 700;
font-size: 40px;
line-height: 40px;
`

const CustomLink = styled(Link)`
  width: 27px;
  height: 18px;
  background: url(${arrowBack}) no-repeat center;
  position: absolute;
  top: 0px;
  left: 0px;

  @media screen and (max-width: 640px) {
    left: 20px;
    top: 90px;
  }
`


const Container = styled.div`
  grid-area: post;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  pointer-events: all;

  @media screen and (min-width: 981px) {
    #feature_image {
      width: 100vw !important;
    }
  }
  
  

  @media screen and (max-width: 640px) {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    border: none;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    max-width: 100vw;
    background-color: #f9f9f8;

    &:after {
      content: "";
      width: 45px;
      height: 1px;
      background: #000;
      position: absolute;
      bottom: 0;
    }
  }
`

const PostContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 43px;

  @media screen and (max-width: 980px) {
    width: 500px;
  }

  @media screen and (max-width: 640px) {
    width: 336px;
  }
`

const Title = styled.h1`
@media screen and (min-width: 981px) {

  width: 940px;
}
  font-weight: 800;
  font-size: 48px;
  line-height: 50px;
  margin-top: 24px;
  text-align: left;

  @media screen and (max-width: 640px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const Meta = styled.p`
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #7d7d7d;
  text-align: left;
  align-self: start;
  margin-top: 24px;
  margin-bottom: 32px;
`

const Author = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0D0D0D;
`

const SubscriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 70px;
  pointer-events: all;
  width: 100%;
  position: relative;
  border: 0.647px solid #0D0D0D; 
  padding: 20px;

  h3 {
    color: #0D0D0D; 
    width: 100%;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; 
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 0.849px solid rgba(25, 25, 26, 0.15); 
    background: transparent;
    height: 50px;

    &::placeholder {
      color: #757575;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; 
    }
  

  }

  &::after {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: none;
    transform: skewY(-30deg);
    transform-origin: left bottom;
    height: calc(100% + 2px);
    width: 12px;
    top: -1px;
    left: calc(100% + 1px);
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: 2px solid #000;
    border-bottom: none;
    transform: skew(-60deg);
    transform-origin: right bottom;
    height: 6px;
    width: calc(100% + 2px);
    bottom: calc(100% + 1px);
    left: -1px;
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  img {
    position: absolute;
    top: -60px;
    right: -60px;
    width: 140px;
    z-index: 10;
  }
`

const CustomDiv = styled.div`
@media screen and (min-width: 981px) {
  position: relative;
  width: 1045px;
}
@media screen and (max-width: 980px) {
  width: 100%;
}
`

const Toc = styled.div`
@media screen and (max-width: 980px) {
  display: none;
}
  width: 350px !important;
  margin-left: 60px;

  .h2{
    margin-left: 5px;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
  }
  .h3{
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;
  }

  .h4{
    margin-left: 15px;
    font-size: 11px;
    font-weight: 500;
  }

  h3 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.56px; 
    margin: 0 0 32px;
  }
  p {
    width: 100%;
    color: #0D0D0D;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.118px; 
    margin-top: 10px;
    
    a {
      text-decoration: none;
    }
  }

@media screen and (max-width: 980px) {
  margin: 0 auto;
  width: 100% !important;
}
  @media screen and (max-width: 640px) {
  width: auto !important;
  margin: 0 auto;
}
`

const TocMobile = styled(Toc)`

h3 {
  margin: 0;
}
margin-bottom: 60px;
display: block;
@media screen and (min-width: 981px) {
  display: none;
}
`


const EmailInput = styled.div`
  width: 100%;
  display: flex;

`

const SubmitButton = styled(ContinueButton)`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 10px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease;
  background: #ffd63e;

  ${props =>
    props.success &&
    css`
      background: #b6fad9;
    `}
`

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 20px;
  box-shadow: 4px -1px #DAF4EA, 4px -1px 0px 0.5px black;
  border: 0.5px solid #000;
`

const FooterCta = styled.div`
  grid-area: footerCta;
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    max-width: 385px;
    text-align: center;
  }

  img {
    width: 100%;
  }
`

const GetStartedButton = styled.a`
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 
  outline: none;
  margin-top: 35px;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &.active {
    text-decoration: underline;
  }

  @media screen and (max-width: 375px) {
    padding: 10px 0;
  }
  cursor: pointer;
  border: 1px solid #000 !important;
  background: #fbd65d;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  padding: 15px 24px;
  width: auto;

  @media screen and (max-width: 980px) and (min-width: 641px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 375px) {
    padding: 15px 24px;
  }
`
const Tag = styled.span`
  border-radius: 12px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 0;
  border: 1px solid #000;
  font-size: 10px;
  text-transform: uppercase;
  position: relative;
  box-sizing: border-box;
  line-height: 10px;
  position: absolute;
  bottom: 20px;

  ${props =>
    props.selected &&
    css`
      padding-left: 26px;

      &::after {
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #000;
        position: absolute;
        align-self: flex-start;
        transform: translateX(-16px);
      }
    `}

  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
`

const PostPreview = ({ data, pageContext }) => {
  const containerRef = useRef()
  const postRef = useRef()
  const [tocArray, setTocArray] = useState([])
  const [post, setPost] = useState(null)
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState("")
  const moreRef = useRef(null);
  const isMobile = useSelector(state => state.global.isMobile);
  const location = useLocation();
  const slug = new URLSearchParams(location.search).get("slug");

  const hScrollLeft = () => {
    const left = isMobile ? 270 : 575
    moreRef.current.scrollBy({
      left: left,
      behavior: 'smooth'
    });
  }

  const hScrollRight = () => {
    const left = isMobile ? 270 : 575
    moreRef.current.scrollBy({
      left: -left,
      behavior: 'smooth'
    });
  }

  const createToc = () => {
    let toc = []
    Array.from(postRef.current.childNodes).forEach(function (element) {
      if (['H1', 'H2', 'H3', 'H4'].includes(element.nodeName)) {
        toc.push({
          id: element.id,
          text: element.innerText,
          type: element.nodeName
        })
      }
    });
    setTocArray(toc)
  }
  const onEmailChange = e => {
    setEmail(e.target.value.trim())
  }
  const submitEmail = () => {
    setSubmitting(true)
    try {
      axios.post(`${serviceURL().api}/subscribe`, {
        email: email,
      }).then(() => {
        setSuccess(true)
        setEmail('')
        setSubmitting(false)

        setTimeout(() => {
          setSuccess(false)
        }, 700);
      })
    } catch (error) {
      console.error(error)
    }


  }

  useEffect(() => {
    createToc()

    const onPageClick = e => {
      if ([...e.target.children].includes(containerRef.current))
        if (post?.primary_tag.name == 'case-study') {
          navigate("/case-studies")
        } else {
          navigate("/blog")
        }
    }

    // window.addEventListener("click", onPageClick)

    return () => window.removeEventListener("click", onPageClick)
  }, [])

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postData = await api.posts.read({ slug: slug, include: "tags,authors" });
        setPost(postData);
        console.log(postData)
      } catch (err) {
      } finally {
      }
    };

    if (slug) {
      fetchPost();
    }
  }, [slug]);

  useEffect(() => {
    if (postRef.current) {
      const images = postRef.current.querySelectorAll("img");
      images.forEach((img) => {
        img.style.width = "100%";
        img.style.height = "auto";
        if (img.src.endsWith(".gif")) {
          img.setAttribute("loading", "lazy");
        }
      });
    }
  }, [post?.html]);

  return (
    <>
      <Helmet>
        <style type="text/css">{`${post?.codeinjection_styles}`}</style>
      </Helmet>
      <SEO
        title={post?.title}
        description={post?.meta_description}
        ogTitle={post?.og_title}
        ogDescription={post?.og_description}
        ogImage={post?.og_image}
        twitterTitle={post?.twitter_title}
        twitterDescription={post?.twitter_description}
        twitterImage={post?.twitter_image}
        canonical={(post?.primary_tag?.name == 'case-study' ? '/case-studies/' : '/blog/') + post?.slug + '/'}
      />

      <>
        <Grid >
          <Container ref={containerRef}>
            <CustomDiv style={{ height: '30px' }} ><CustomLink
              to={post?.primary_tag?.name == 'case-study' ? '/case-studies/' : '/blog/'}
              onClick={e => {

              }}
            />
            </CustomDiv>

            <PostContainer>
              <CustomDiv style={{ display: 'inherit' }}>{post?.primary_tag && <Tag>{post?.primary_tag?.name}</Tag>}</CustomDiv>
              <CustomDiv><Title>{post?.title}</Title></CustomDiv>
              <CustomDiv>
                <Meta>
                  <Avatar src={post?.primary_author.profile_image || AvatarPic} />
                  <div>
                    <Author>By {post?.primary_author.name}</Author>
                    Published {post?.published_at_pretty} <br />
                  </div>
                </Meta>
              </CustomDiv>
              <img id='feature_image' src={post?.feature_image} />

              {/* <PostHTML ref={postRef} dangerouslySetInnerHTML={{ __html: post?.html }} /> */}
              <PostHTML >
                <TocMobile>
                  {tocArray.length !== 0 && (
                    <>
                      <h3>Content</h3>
                      {tocArray.map((element, index) => (
                        <p key={index} className={element.type.toLowerCase()}><a href={'#' + element.id}>
                          {element.type == 'H3' ? (<span style={{ marginRight: '5px' }}>○</span>) : null} {element.text}</a></p>
                      ))}
                    </>
                  )}

                  <SubscriptionBox color="#4BC1FF">
                    <h3>Subscribe to our newsletter!</h3>
                    <EmailInput>
                      <input
                        onChange={onEmailChange}
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            isEmail(email) && submitEmail()
                          }
                        }}
                        value={email}
                        placeholder="Enter your email" type="text" />
                      <SubmitButton
                        show={!!email && isEmail(email)}
                        onClick={submitEmail}
                        icon={success ? "success" : submitting ? "loading" : "next"}
                      />
                    </EmailInput>
                    <img src={Sub} />

                  </SubscriptionBox>


                </TocMobile>
                <div ref={postRef} dangerouslySetInnerHTML={{ __html: post?.html }}></div>

                <Toc>
                  {tocArray.length !== 0 && (
                    <>
                      <h3>Content</h3>
                      {tocArray.map((element, index) => (
                        <p key={index} className={element.type.toLowerCase()}><a href={'#' + element.id}>
                          {element.type == 'H3' ? (<span style={{ marginRight: '5px' }}>○</span>) : null} {element.text}</a></p>
                      ))}
                    </>
                  )}

                  <SubscriptionBox color="#4BC1FF">
                    <h3>Subscribe to our newsletter!</h3>
                    <EmailInput>
                      <input
                        onChange={onEmailChange}
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            isEmail(email) && submitEmail()
                          }
                        }}
                        value={email}
                        placeholder="Enter your email" type="text" />
                      <SubmitButton
                        show={!!email && isEmail(email)}
                        onClick={submitEmail}
                        icon={success ? "success" : submitting ? "loading" : "next"}
                      />
                    </EmailInput>
                    <img src={Sub} />

                  </SubscriptionBox>


                </Toc>


              </PostHTML>
            </PostContainer>
          </Container>
          <FooterCta>

            <H1>Grow faster with
              a better onboarding</H1>
            <p>Get recommendations on how to take your existing flow to the next level!</p>

            <GetStartedButton
              href="/contact-us"
            >
              Schedule a call
            </GetStartedButton>
            <img src={FooterImg} />

          </FooterCta>

        </Grid>
      </>
    </>
  )
}

export default PostPreview