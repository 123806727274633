import React from "react";
import styled from "styled-components"
import ThumbnailImg from '../../assets/images/thumbnail.png'
import { Link } from "gatsby"


const Container = styled(Link)`
    width: 547px;
    margin-right: 31px;
    border: 1px solid #000000;
    box-shadow: 4px 4px 0px rgba(13, 13, 13, 0.1);
    cursor: pointer;

  @media screen and (max-width: 640px) {
    width: 310px;
  }
`
const Content = styled.div`
    padding: 32px;
    p{
        max-height: 37px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

`
const Thumbnail = styled.div`
    height: 270px;
    overflow: hidden;
`
const Date = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-transform: uppercase;
    color: #4A4A4A;
`
const Title = styled.div`
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #0D0D0D;
        margin-bottom: 12px;
`
const Read = styled.div``

const Card = ({ data, id, thumbnail }) => {
    return (
        <Container
            state={{ post: data }}
            to={data.primary_tag?.name == 'case-study' ? `/case-studies/${data.slug}/` : data.fromApi ? `/blog/post?slug=${data.slug}` : `/blog/${data.slug}/`}
            id={id}>
            {thumbnail ?
                (<Thumbnail>
                    <img src={ThumbnailImg} />
                </Thumbnail>)
                : null}
            <Content>
                <Date>{data.created_at_pretty}</Date>
                <Title>{data.title}</Title>
                <p>{data.excerpt}</p>
            </Content>
        </Container>
    )
}

export default Card;